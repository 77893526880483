import React from 'react';
import { Descriptions, Card, Row } from 'antd';
import { formatDateDDMMYYYY } from 'utils/cleanUtils';
import Gallery from 'components/adverts/detail/Gallery';
import STitle from 'components/common/STitle';
import { useTranslation } from 'react-i18next';

function DiverseInfo(props) {
  const { data, renderActions } = props;
  const { t } = useTranslation();
  return (
    <>
      <STitle className="wizard-title" level={4} titleText={t('diversesresultform.diverseinformation')} />
      {renderActions()}
      <Row>
        <Card>
          <Descriptions style={{ marginTop: 50 }}>
            <Descriptions.Item label={t('table.offertype')}>
              {data && data.offerType ? data.offerType : ''}
            </Descriptions.Item>
            <Descriptions.Item label={t('table.location')}>
              {data && data.location ? data.location : ''}
            </Descriptions.Item>
            <Descriptions.Item label={t('mountcard.departuredeadline')}>
              {data && data.endProcess ? formatDateDDMMYYYY(data.endProcess) : ''}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Row>
      <Row style={{ marginTop: 40 }}>
        <Card>
          <Gallery pics={data ? data.advertPics : null} />
        </Card>
      </Row>
    </>
  );
}

export default DiverseInfo;
