import React from 'react';
import { Descriptions, Row, Card } from 'antd';
import PropTypes from 'prop-types';
import STitle from 'components/common/STitle';
import Gallery from 'components/adverts/detail/Gallery';
import './style.css';
import { caseTypes, locationTypeEnum } from 'models/enums';
import { useTranslation } from 'react-i18next';

function UbicationInfo(props) {
  const { data, renderActions } = props;
  const { caseTypeId } = data;
  const { t } = useTranslation();

  const getLabelByLocationType = locationTypeId => {
    let label;
    switch (locationTypeId) {
      case locationTypeEnum.WORKSHOP:
        label = t('locationform.workshopname');
        break;
      case locationTypeEnum.CAR_POUND:
        label = t('cases.search.branchprovidercarpound');
        break;
      case locationTypeEnum.OTHER:
        label = t('locationform.destinationname');
        break;

      default:
        break;
    }
    return label;
  };

  return (
    <>
      <STitle className="wizard-title" level={4} titleText={t('locationform.header')} />
      {renderActions()}
      <Row>
        <Card id="ubication-description">
          <Descriptions layout="vertical">
            {caseTypeId === caseTypes.VEHICULOS && (
              <>
                <Descriptions.Item label={t('locationform.locationtype')}>
                  {data && data.locationType ? data.locationType : ''}
                </Descriptions.Item>
                <Descriptions.Item
                  label={getLabelByLocationType(
                    data && data.locationName ? data.locationTypeId : undefined
                  )}
                  className="branchName"
                >
                  {data && data.locationName ? data.locationName : ''}
                </Descriptions.Item>
              </>
            )}
            <Descriptions.Item label={t('usersform.country')}>
              {data && data.address.country ? data.address.country : ''}
            </Descriptions.Item>
            <Descriptions.Item label={t('usersform.postalcode')}>
              {data && data.address.zipCode ? data.address.zipCode : ''}
            </Descriptions.Item>
            <Descriptions.Item label={t('usersform.state')} span={1}>
              {data && data.address.state ? data.address.state : ''}
            </Descriptions.Item>
            <Descriptions.Item label={t('usersform.delegation')}>
              {data && data.address.neighborhood ? data.address.town : ''}
            </Descriptions.Item>
            <Descriptions.Item label={t('usersform.city')}>
              {data && data.address.city ? data.address.city : ''}
            </Descriptions.Item>
            <Descriptions.Item label={t('usersform.neighborhood')}>
              {data && data.address.town ? data.address.neighborhood : ''}
            </Descriptions.Item>

            <Descriptions.Item label={t('usersform.numext')}>
              {data && data.address.outNumber ? data.address.outNumber : ''}
            </Descriptions.Item>
            <Descriptions.Item label={t('usersform.numint')}>
              {data && data.address.inNumber ? data.address.inNumber : ''}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions layout="vertical">
            <Descriptions.Item label={t('usersform.street')}>
              {data && data.address.roadName ? data.address.roadName : ''}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Row>
      <Row style={{ marginTop: 40 }}>
        <Card>
          <Gallery pics={data ? data.advertPics : null} />
        </Card>
      </Row>
    </>
  );
}

UbicationInfo.propTypes = {
  renderActions: PropTypes.func
};

UbicationInfo.defaultProps = {
  renderActions: () => { }
};

export default UbicationInfo;
