import React, { useState } from 'react';
import DocumentsGroup from 'components/common/catalogs/DocumentsGroup';
import DocumentsTypeSelect from 'components/common/catalogs/DocumentsTypeSelect';
import { Row, Form, Col, Modal, Descriptions, Card } from 'antd';
import { connect } from 'react-redux';
import { caseFilesRequest, documentsCaseRequest } from 'redux/actions/filesActions.js';
import ListFiles from 'components/ListFiles';
import { getDownloadFileNew } from 'services/cases';
import PreviewPDF from 'components/common/PreviewPDF';
import { requestDownloadFilePreview } from 'redux/actions/downloadFile';
import STitle from 'components/common/STitle';
import { formatDateDDMMYYYY } from 'utils/cleanUtils';
import { documentsTypeEnum } from 'models/enums';
import { useTranslation } from 'react-i18next';

function FilesInfoForm(props) {
  const {
    caseId,
    form,
    data,
    caseDocuments,
    documentsCaseRequest: documentsCaseReq,
    currentFileList,
    renderActions
  } = props;
  const { setFieldsValue, getFieldValue } = form;
  const [selectedGroup, setSelectedGroup] = useState(undefined);
  const [previewIsVisible, setPreviewIsVisible] = useState(false);
  const [file, setFile] = useState();
  const { t } = useTranslation();

  const handleGroupOnChange = value => {
    setFieldsValue({ documentTypes: undefined });
    setSelectedGroup(value);
  };

  const handleDocumentsTypeSelect = documentId => {
    documentsCaseReq({ documentTypeId: documentId, caseId: data.caseId });
  };

  const closeModal = () => {
    setPreviewIsVisible(false);
  };

  const onDownloadSuccess = dataFile => {
    setPreviewIsVisible(true);
    setFile({
      content: dataFile.content,
      name: dataFile.name,
      type: dataFile.headerBase64
    });
  };

  const downloadOptions = {
    withPreview: true,
    extraParams: { caseId: caseId, base64: false },
    serviceToDownload: getDownloadFileNew,
    onDownloadSuccess: onDownloadSuccess,
    onDownloadFail: () => { },
    withDownloadForced: false,
    order: 'downloadCaseFile'
  };

  //TODO: Extract this into a component
  const renderLastBillFields = documentValue => {
    let fields = null;
    if (documentValue === documentsTypeEnum.ULTIMA_FACTURA) {
      if (caseDocuments && caseDocuments.data) {
        if (caseDocuments.data && caseDocuments.data[0].metadata) {
          return caseDocuments.data[0].metadata.map(e => {
            if (e.text !== '') {
              return (
                <Descriptions.Item key={e.id} label={e.label} layout="vertical">
                  {e.id === 2 ? formatDateDDMMYYYY(e.text) : e.text}
                </Descriptions.Item>
              );
            }
          });
        }
      }
    }
    return fields;
  };

  return (
    <>
      <STitle className="wizard-title" level={4} titleText={t('filesinfo.filesinfo')} />
      {renderActions()}
      <Form className="text-left">
        <Row justify="space-around" align="middle" gutter={20} type="flex">
          <Col lg={6} md={6} sm={24} xs={24}>
            <DocumentsGroup
              onlyBuyerDocs
              form={form}
              onChange={value => handleGroupOnChange(value)}
            />
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <DocumentsTypeSelect
              onChange={value => handleDocumentsTypeSelect(value)}
              form={form}
              groupId={selectedGroup}
            />
          </Col>
        </Row>

        <Card>
          <Descriptions bordered layout="vertical">
            {renderLastBillFields(getFieldValue('documentTypes', caseDocuments))}
          </Descriptions>
        </Card>

        <Row justify="center" align="middle" gutter={20} type="flex">
          <Col md={18} sm={24}>
            <ListFiles
              pageSize={8}
              source={currentFileList}
              canDownloadFiles={false}
              downloadOptions={downloadOptions}
              showIconOnText={false}
            />
          </Col>
        </Row>

        <Modal width={650} footer={null} visible={previewIsVisible} onCancel={closeModal}>
          <PreviewPDF
            titleDocument={file && file.name ? file.name : undefined}
            base64={file && file.content ? file.content : undefined}
            type={file && file.type ? file.type : undefined}
          />
        </Modal>
      </Form>
    </>
  );
}

const FilesInfo = Form.create({ name: 'files-info' })(FilesInfoForm);

function mapStateToProps(state) {
  return {
    currentFileList: state.files.currentFileList,
    caseDocuments: state.files.caseDocuments
  };
}

export default connect(mapStateToProps, {
  documentsCaseRequest,
  caseFilesRequest,
  requestDownloadFilePreview
})(FilesInfo);
