import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import InfiniteCarousel from 'react-leaf-carousel';
import ImageGallery from 'react-image-gallery';
import { buildURLImage, getItemFromLocal } from 'utils';

import useIsMounted from 'hooks/useIsMounted';
import SImage from 'components/common/SImage';

import './style.css';
import { createArrOfImagesBlob, createInvidualImageUrl } from 'utils/helperActions';
import { useDispatch, useSelector } from 'react-redux';
import { getAdvertImagesFullScreen } from 'redux/actions/buyersActions';

function Gallery(props) {
  const { pics, fromRedux, caseId, advertId } = props;
  const dispatch = useDispatch();
  const refGallery = useRef(null);
  const isMounted = useIsMounted();
  const tokenPic = getItemFromLocal('tokenPic');
  const images = useSelector(state => state.buyers.images.adverts[advertId]);

  const [isFullScreenGallery, setIsFullScreenGallery] = useState(false);

  const createGallery = () => {
    let InLineGallery;
    if (pics !== null && typeof pics != undefined) {
      InLineGallery = (
        <InfiniteCarousel
          breakpoints={[
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3
              }
            }
          ]}
          dots={true}
          showSides={true}
          sidesOpacity={0.5}
          sideSize={0.1}
          slidesToScroll={4}
          slidesToShow={4}
          scrollOnDevice={true}
          autoCycle={true}
        >
          {pics.map(pic => {
            const picUrl = individualUrlPic(pic);
            return (
              <div role="presentation" key={pic.id}>
                <SImage alt={pic.name} src={picUrl} onClick={handleGalleryViewChange} />
              </div>
            );
          })}
        </InfiniteCarousel>
      );
    }
    return InLineGallery;
  };

  useEffect(() => {
    if (refGallery.current) refGallery.current.toggleFullScreen();

    if(isFullScreenGallery){
      const imagesWithoutFullScreen = images?.filter(pic => pic.caseId === caseId && !pic.blob.fullScreen);
      dispatch(getAdvertImagesFullScreen({imagesWithoutFullScreen, advertId, caseId}));
    }
  }, [isFullScreenGallery]);

  const handleGalleryViewChange = () => {
    setIsFullScreenGallery(true);
  };

  const createArrayOfImages = () => {
    let arrayOfPics = [];
    if(fromRedux && advertId && caseId){
      arrayOfPics = createArrOfImagesBlob(images, caseId);
    }else{
      pics.forEach(pic => {
        const picUrl = buildURLImage(tokenPic, pic.id, 'Buyers/Api/v1/Pics', !isFullScreenGallery);
        arrayOfPics = [
          ...arrayOfPics,
          {
            original: picUrl,
            thumbnail: picUrl
          }
        ];
        return arrayOfPics;
      });
    }
    
    return arrayOfPics;
  };

  const individualUrlPic = pic => {
    let picUrl;
    if(fromRedux && advertId && caseId){
      picUrl = createInvidualImageUrl(images, caseId, pic.id);
    }else{
      picUrl = buildURLImage(tokenPic, pic.id, 'Buyers/Api/v1/Pics', !isFullScreenGallery);
    }
    return picUrl;
  };

  const onScreenChange = isFullScreen => {
    if (!isFullScreen && isMounted.current) setIsFullScreenGallery(false);
  };

  return isFullScreenGallery ? (
    <div>
      <ImageGallery
        ref={refGallery}
        lazyLoad={true}
        galleryWidth={'100%'}
        items={createArrayOfImages()}
        showThumbnails
        showBullets
        showNav
        showFullscreenButton
        showPlayButton={false}
        onScreenChange={onScreenChange}
      />
    </div>
  ) : (
    <div data-testid="normal" role="presentation">
      {createGallery()}
    </div>
  );
}

Gallery.propTypes = {
  pics: PropTypes.array
};

Gallery.defaultProps = {
  pics: null
};

export default Gallery;
