import React from 'react';
import { Descriptions, Row, Card } from 'antd';
import Gallery from 'components/adverts/detail/Gallery';
import STitle from 'components/common/STitle';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

function VehicleInfo(props) {
  const { data, renderActions } = props;
  const { t } = useTranslation();

  return (
    <>
      <STitle className="wizard-title" level={4} titleText={t('vehicleform.vehicleinfo')} />
      {renderActions()}
      <Row>
        <Card>
          <Descriptions style={{ marginTop: 50 }}>
            <Descriptions.Item label={t('vehicleform.unittype')}>
              {data && data.vehicleType ? data.vehicleType : ''}
            </Descriptions.Item>
            <Descriptions.Item label={t('vehicleform.type')}>{data && data.type ? data.type : ''}</Descriptions.Item>
            <Descriptions.Item label={t('vehicleform.brand')}>
              {data && data.brand ? data.brand : ''}
            </Descriptions.Item>
            <Descriptions.Item label={t('vehicleform.model')}>
              {data && data.model ? data.model : ''}
            </Descriptions.Item>
            <Descriptions.Item label={t('vehicleinfo.biddingperiod')} span={2}>
              {data && data.biddingPeriod
                ? moment(data.biddingPeriod).format('DD/MM/YYYY HH:mm:ss')
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label={t('casesuniversept.register.location')}>
              {data && data.location ? data.location : ''}
            </Descriptions.Item>
            <Descriptions.Item label={t('vehicleform.vehicleserial')}>
              {data && data.vehicleSerial ? data.vehicleSerial : ''}
            </Descriptions.Item>
            <Descriptions.Item label={t('mountcard.departuredeadline')}>
              {data && data.endProcess ? moment(data.endProcess).format('DD/MM/YYYY') : ''}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Row>
      <Row style={{ marginTop: 40 }}>
        <Card>
          <Gallery pics={data ? data.advertPics : null} />
        </Card>
      </Row>
    </>
  );
}

export default VehicleInfo;
