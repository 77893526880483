import React, { forwardRef } from 'react';
import SInput from 'components/common/SInput';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const DeliveryCommentForm = forwardRef((props, ref) => {
  const { form, data } = props;
  const { t } = useTranslation();

  return (
    <Form ref={ref}>
      <SInput
        label={t('inventory.vehicle.exit.detail.deliverycomment')}
        form={form}
        inputType="textarea"
        inputKey="buyerComment"
        maxLength={300}
        placeholder={t('inventory.vehicle.exit.detail.deliverycomment')}
        initialValue={data && data.comment ? data.comment : undefined}
        isRequired
      />
    </Form>
  );
});

DeliveryCommentForm.propTypes = {
  form: PropTypes.object.isRequired
};

DeliveryCommentForm.displayName = 'DeliveryComment';

const DeliveryComment = Form.create({ name: 'email-in-modal' })(DeliveryCommentForm);

export default DeliveryComment;
